<template>
  <div>
    <GlobalIncome></GlobalIncome>
  </div>
</template>
<script>
import GlobalIncome from "@/views/commons/components/reports/tabs/global-income/GlobalIncome.vue";

export default {
  components: {
    GlobalIncome,
  },
};
</script>